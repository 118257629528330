import React from 'react';
import {
 Flex
} from "@aws-amplify/ui-react";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


function Product (props){

 const TEXTS0 = ['', props.name];


 const TEXTS1 = ['',props.txt1];
 const TEXTS2 = ['',props.txt2];
 const TEXTS3 = ['',props.txt3];
 const TEXTS4 = ['',props.txt4];

 const TEXTS5 = ['',props.txt5];
 const TEXTS6 = ['',props.txt6];
 const TEXTS7 = ['',props.txt7];



	return (<>
	
	

	



	<Flex
	direction='row'
	
	bottom='0'
	textAlign="center"
	width="96vw"
    gap='0'
	//backgroundColor="yellow"
		paddingBottom='35px'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	minWidth="4vw"	
	  
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent='flex-end'
	width="94vw"
	height='auto'	
	>






	
	
<Flex
	direction='column'
	justifyContent="flex-start"
	alignItems="center"
	textAlign="center"
	//	minWidth="100vw"
	gap='0'
	//minHeight='80vh'
	//backgroundColor='cyan'
	//marginTop='10px'

	>




<div className='pageContentP'>











<Flex
			direction="column"
	
			alignItems="center"
			textAlign="center"
			justifyContent='center'
		//	backgroundColor='purple'
		    //minHeight='75vh'
		gap='0'
		//	paddingTop='6px'
		//	marginLeft='10vw'
		//	marginRight='10vw'
			//paddingBottom='calc(20px + 1.5vw)'
	//		paddingBottom='20px'
		>



<Flex		
			direction="column"
			alignItems="center"
			gap="0"
			fontSize='calc(10px + 5vw)'
			opacity='.85'
		//	backgroundColor='blue'
			//maxHeight='4vh'
		//	margin='10px'
			
			>
			
			
    
	
			<span className = "pageContentHedderP">{TEXTS0}</span>

			

						</Flex>

























						<Flex
					direction='row'
					justifyContent='center'
					alignItems='center'
					alignContent='flex-start'
					rowGap='1.2vh'
					columnGap='calc(10px + 2vw)'
					//backgroundColor='green'
					wrap='wrap'
					//marginTop='0vw'
					//paddingTop='20px'
					//paddingBottom='0px'
					//	minHeight='100px + 20vw'
					minHeight='60vh'
					//	paddingTop='.3vw'
				//	width='96vw'
							overflow='hidden'
				>

					<Flex
						direction='column'
						justifyContent='center'
						gap='0'
					//	backgroundColor='blue'
						borderColor='#2568FB'
						borderStyle='hidden'
					//	minHeight='calc(200px +  10vw)'
					//	minWidth='calc(400px + 15vw)'
						alignItems='center'
					overflow='hidden'
					fontSize='calc(18px + .5vw)'
					maxWidth='92vw'
					//minWidth='60vw'
			//		maxHeight='calc(30vh + 8vw)'
				//	minHeight='20vh'
					>

						<img  style={{paddingTop:'0px',  height:'calc(16vw + 6vh + 100px)' , overflow:'hidden'}}  src={props.img} alt=""  />
 					</Flex>

					<Flex
						direction='column'
						justifyContent='flex-start'
						gap='2vw'
					//	backgroundColor='yellow'
				//		color='black'
					//	backgroundColor='#e7e7e7'
						borderColor='#2568FB'
						borderStyle='hidden'
						//paddingTop='10px'
						alignItems='center'
					//	marginLeft='20px'
					//	marginRight='20px'
				//		width='auto'
						//minHeight='calc(200px +  10vw)'
				//		grow='1'
				//		wrap='wrap'
						fontSize='calc(16px + .5vw)'
					>







						<Flex
							direction='column'
						//	fontWeight='bold'
						//	textAlign='left'
				//			backgroundColor='red'
						//	minWidth='22vw'
							gap="1vw"
						//	marginTop='15px'
							
						>
							<span>{TEXTS1}</span>
							<span>{TEXTS2}</span>
							<span>{TEXTS3}</span>
							<span>{TEXTS4}</span>
						</Flex>

						<a href="https://app.gserver.cloud/signup"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >

						<Flex
							direction='column'
							backgroundColor='#FB8B24'
						//	borderColor='#FB8B24'
					//		borderStyle='solid'
							color='black'
							paddingLeft='45px'
							paddingRight='45px'
							//marginLeft='10px'
							//marginRight='10px'
							gap='0'
						//	borderRadius='5px'
						//	marginBottom='15px'
							fontWeight='bold'
							marginTop='5px'
						>
							<span>{TEXTS6}</span>
							<span>{TEXTS5}</span>
							<span>{TEXTS7}</span>
						</Flex>


			
		</a>

					</Flex>


				</Flex>


































	  
		</Flex>












	</div>
	




	</Flex>







	</Flex>





<Flex 
direction='column'
justifyContent="center"
alignItems="center"
textAlign="center"
grow='1'
>
</Flex>
</Flex>




	






 </>
		
	
	)
}

export default Product;
