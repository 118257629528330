import React from 'react';
import {
 Flex
} from "@aws-amplify/ui-react";
import Navbar from './navbar_other';
import Product from './product';

import img1 from '../assets/1.png';

function Palworld (){

 const name = ['','PALWORLD'];

 const TEXTS1 = ['','24/7 Dedicated Game Server'];
 const TEXTS2 = ['','Automatic World Backup'];
 const TEXTS3 = ['','Personal IP Address'];
 const TEXTS4 = ['','Simple Setup and Control'];

 const TEXTS5 = ['','32 Player $40/mo'];
 const TEXTS6 = ['','16 Player $25/mo'];
 const TEXTS7 = ['','ORDER NOW!'];


return (
<>	
	<div className = "Body" >


	<Navbar></Navbar>
		

		<Product name={name} img={img1} txt1={TEXTS1} txt2={TEXTS2} txt3={TEXTS3} txt4={TEXTS4} txt5={TEXTS5} txt6={TEXTS6} txt7={TEXTS7} ></Product>



	</div>
 </>
		
	)
}

export default Palworld;
