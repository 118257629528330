import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
 // Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
//import TextTransition, { presets } from 'react-text-transition';
import { Link } from 'react-router-dom';
import Navbar from './navbar_home';
//import bg from "../bg2.jpg";
//import background from "./ncs1s.jpg";
//import background from "../ncs5a.jpg";
//import img1 from "../ncs10-1a.png";
//import img2 from "../ncs10-2a.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import img1c from '../assets/1j.png';
import img2c from '../assets/2j.png';
import img3c from '../assets/3j.png';
import img4c from '../assets/4j.jpg';
import img5c from '../assets/5j.jpg';
import img6c from '../assets/6j.jpg';
import img7c from '../assets/7j.jpg';
import img8c from '../assets/8j.jpg';
import img9c from '../assets/9j.png';

import img1 from '../assets/1m.png';
import img2 from '../assets/2m.png';
import img3 from '../assets/3m.png';
import img4 from '../assets/4m.jpg';
import img5 from '../assets/5m.jpg';
import img6 from '../assets/6m.jpg';
import img7 from '../assets/7m.jpg';
import img8 from '../assets/8m.jpg';
import img9 from '../assets/9m.png';

import img1f from '../assets/1j.png';
import img2f from '../assets/2j.png';
import img3f from '../assets/3j.png';
import img4f from '../assets/4j.jpg';
import img5f from '../assets/5j.jpg';
import img6f from '../assets/6j.jpg';
import img7f from '../assets/7j.jpg';
import img8f from '../assets/8j.jpg';
import img9f from '../assets/9j.png';

import img1r from '../assets/1m.png';
import img2r from '../assets/2m.png';
import img3r from '../assets/3m.png';
import img4r from '../assets/4m.jpg';
import img5r from '../assets/5m.jpg';
import img6r from '../assets/6m.jpg';
import img7r from '../assets/7m.jpg';
import img8r from '../assets/8m.jpg';
import img9r from '../assets/9m.png';

import img1s from '../assets/1j.png';
import img2s from '../assets/2j.png';
import img3s from '../assets/3j.png';
import img4s from '../assets/4j.jpg';
import img5s from '../assets/5j.jpg';
import img6s from '../assets/6j.jpg';
import img7s from '../assets/7j.jpg';
import img8s from '../assets/8j.jpg';
import img9s from '../assets/9j.png';

import img1t from '../assets/1m.png';
import img2t from '../assets/1m.png';
import img3t from '../assets/1m.png';
import img4t from '../assets/1m.png';
import img5t from '../assets/1m.png';
import img6t from '../assets/1m.png';
import img7t from '../assets/1m.png';
import img8t from '../assets/1m.png';
import img9t from '../assets/1m.png';

import img1u from '../assets/1u.png';
import img2u from '../assets/2u.png';
import img3u from '../assets/3u.png';
import img4u from '../assets/4u.jpg';
import img5u from '../assets/5u.jpg';
import img6u from '../assets/6u.jpg';
import img7u from '../assets/7u.jpg';
import img8u from '../assets/8u.jpg';
import img9u from '../assets/9u.png';

import img1v from '../assets/1v.png';
import img2v from '../assets/2v.png';
import img3v from '../assets/3v.png';
import img4v from '../assets/4v.jpg';
import img5v from '../assets/5v.jpg';
import img6v from '../assets/6v.jpg';
import img7v from '../assets/7v.jpg';
import img8v from '../assets/8v.jpg';
import img9v from '../assets/9v.png';

import img1ul from '../assets/1ul.png';
import img2ul from '../assets/2ul.png';
import img3ul from '../assets/3ul.png';
import img4ul from '../assets/4ul.jpg';
import img5ul from '../assets/5ul.jpg';
import img6ul from '../assets/6ul.jpg';
import img7ul from '../assets/7ul.jpg';
import img8ul from '../assets/8ul.jpg';
import img9ul from '../assets/9ul.png';

import img1vl from '../assets/1vl.png';
import img2vl from '../assets/2vl.png';
import img3vl from '../assets/3vl.png';
import img4vl from '../assets/4vl.jpg';
import img5vl from '../assets/5vl.jpg';
import img6vl from '../assets/6vl.jpg';
import img7vl from '../assets/7vl.jpg';
import img8vl from '../assets/8vl.jpg';
import img9vl from '../assets/9vl.png';


import img1ul2 from '../assets/1ul2.png';
import img2ul2 from '../assets/2ul2.png';
import img3ul2 from '../assets/3ul2.png';
import img4ul2 from '../assets/4ul2.jpg';
import img5ul2 from '../assets/5ul2.jpg';
import img6ul2 from '../assets/6ul2.jpg';
import img7ul2 from '../assets/7ul2.jpg';
import img8ul2 from '../assets/8ul2.jpg';
import img9ul2 from '../assets/9ul2.png';

import img1vl2 from '../assets/1j.png';
import img2vl2 from '../assets/2j.png';
import img3vl2 from '../assets/3j.png';
import img4vl2 from '../assets/4j.jpg';
import img5vl2 from '../assets/5j.jpg';
import img6vl2 from '../assets/6j.jpg';
import img7vl2 from '../assets/7j.jpg';
import img8vl2 from '../assets/8j.jpg';
import img9vl2 from '../assets/9j.png';

import img1wl2 from '../assets/1wl2.png';
import img2wl2 from '../assets/2wl2.png';
import img3wl2 from '../assets/3wl2.png';
import img4wl2 from '../assets/4wl2.jpg';
import img5wl2 from '../assets/5wl2.jpg';
import img6wl2 from '../assets/6wl2.jpg';
import img7wl2 from '../assets/7wl2.jpg';
import img8wl2 from '../assets/8wl2.jpg';
import img9wl2 from '../assets/9wl2.png';


import img1a from '../assets/1j.png';
import img2a from '../assets/2j.png';
import img3a from '../assets/3j.png';
import img4a from '../assets/4j.jpg';
import img5a from '../assets/5j.jpg';
import img6a from '../assets/6j.jpg';
import img7a from '../assets/7j.jpg';
import img8a from '../assets/8j.jpg';
import img9a from '../assets/9j.png';

function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 //var tmpgap = '0rem';

 const TEXTS1 = ['','Performance Game Servers'];
 const TEXTS2 = ['','Game Servers'];
 const TEXTS3a = ['','High Server Performance, Low Latency'];
 const TEXTS3b = ['','Automated Backups, Easy Reset and Restore'];
 const TEXTS3c = ['','Dedicated IP, Simple Setup and Control'];



	return (<>
	
	<div className = "Body" style={{backgroundColor:'', paddingBottom:'20px'}} >

	
	<Navbar></Navbar>






<div className='pageContentH' style={{zIndex:'5' , overflow:'hidden'}} >




<Flex
			direction="row"
			justifyContent='center'
			minWidth='100vw'	
			//backgroundColor='purple'
	gap='0px'

		>



			<Flex
			direction='column'
			gap='0px'
			//backgroundColor='green'
			justifyContent='center'
			>
    
	
			<span className = "pageContentHedderH" >{TEXTS1}</span>

			<span className = "mid-bar">7/1/2024 - CLOSED BETA IS <span className='live'>LIVE!</span>   </span>

				


	  

</Flex>





					


					


















	  
		</Flex>

	






		</div>











	




















		<div className='cMobile' style={{ flexDirection:'column',  position:'relative' ,  backgroundColor:'',   overflow: 'hidden' ,  justifyContent:'flex-end', minHeight:'calc(460px + 14vw)',  maxHeight:'61vh' ,   zIndex:'6'}}>   			
				<Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img  src={img1a} alt="" style={{height:'auto'}} />
				
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2a} alt="" style={{height:'auto'}}   />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3a} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6a} alt="" style={{height:'auto'}}  />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4a} alt="" style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5a} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7a} alt="" style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8a} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9a} alt="" style={{height:'auto'}}  />
			 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel>   

			 <div className="cType">
					PH
				</div>

			 </div>
			 




















			 <div className='cMed' style={{flexDirection:'column',  position:'relative' , paddingBottom:'30px',  backgroundColor:'', overflow: 'hidden' , justifyContent:'flex-end', zIndex:'6',  minHeight:'580px',  maxHeight:'64vh'}}>
   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500}  dynamicHeight={false} centerMode={true} centerSlidePercentage={54}> 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1f} alt=""   />
				
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8f} alt=""  />
					 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9f} alt=""  />
			 <p className="legend" style={{ fontSize:'2vw', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel>   
			 <div className="cType">
					TB
				</div>
			 </div>



			 

			 <div className='cPC' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'550px',  maxHeight:'61vh'  }}>
   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={42} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1}  alt="" />
				
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2}  alt="" />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3}  alt=""  />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6}     alt=""  />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4}   alt="" />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5}    alt=""  />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7}    alt=""  />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8}    alt="" />
					 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9}   alt="" />
			 <p className="legend" style={{ fontSize:'2.5vw', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel>   

				<div className="cType">
					HD
				</div>

			 </div>

			 


			 <div className='cWide' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'550px',  maxHeight:'61vh'   }}>
<Flex
direction='row'
justifyContent='center'
//paddingLeft='calc(4vw - 80px)'
//paddingRight='calc(4vw - 80px)'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={33.5} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1v} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2v}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3v}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6v}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4v}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5v}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7v}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8v}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9v}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
				 4k-W
				</div>
			 </div>










			 <div className='cLong' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',    overflow: 'hidden' , zIndex:'6', minHeight:'480px',  maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'
paddingLeft='0vw'
paddingRight='0vw'

>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={45}> 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1f} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2f}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3f}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6f}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4f}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5f}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7f}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8f}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9f}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
					HD-L
				</div>
			 </div>






			 <div className='cWideLong' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6',  minHeight:'500px',  maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'
//paddingLeft='calc(4vw - 80px)'
//paddingRight='calc(4vw - 80px)'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={33.5} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1vl} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2vl}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3vl}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6vl}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4vl}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5vl}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7vl}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8vl}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9vl}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
				 4k-W-L
				</div>
			 </div>










			 <div className='cMobileLong' style={{ flexDirection:'column',  position:'relative' ,  backgroundColor:'',   overflow: 'hidden' ,  justifyContent:'flex-end', minHeight:'500px',  maxHeight:'62vh' ,   zIndex:'6'}}>   			
				<Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img  src={img1a} alt="" style={{height:'auto'}} />
				
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2a} alt="" style={{height:'auto'}}   />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3a} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6a} alt="" style={{height:'auto'}}  />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4a} alt="" style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5a} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7a} alt="" style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8a} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9a} alt="" style={{height:'auto'}}  />
			 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel>   

			 <div className="cType">
					PH-L
				</div>

			 </div>
			 









			 <div className='cTabletLong' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'500px',  maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={73} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1f} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2f}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3f}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6f}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4f}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5f}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7f}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8f}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9f}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
					TB-L
				</div>
			 </div>











			 <div className='cMobileLong2' style={{ flexDirection:'column',  position:'relative' ,  backgroundColor:'',   overflow: 'hidden' ,  justifyContent:'flex-end', minHeight:'800px',  maxHeight:'62vh' ,   zIndex:'6'}}>   			
				<Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img  src={img1wl2} alt="" style={{height:'auto'}} />
				
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2wl2} alt="" style={{height:'auto'}}   />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3wl2} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6wl2} alt="" style={{height:'auto'}}  />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4wl2} alt="" style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5wl2} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7wl2} alt="" style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8wl2} alt=""  style={{height:'auto'}} />
					 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9wl2} alt="" style={{height:'auto'}}  />
			 <p className="legend" style={{ fontSize:'3vw', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel>   

			 <div className="cType">
					PH-XL
				</div>

			 </div>
			 



		
			 <div className='cLong2' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',    overflow: 'hidden' , zIndex:'6', minHeight:'500px', maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'
paddingLeft='0vw'
paddingRight='0vw'

>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={60}> 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1wl2} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2wl2}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3wl2}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6wl2}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4wl2}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5wl2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7wl2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8wl2}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9wl2}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
					HD-XL
				</div>
			 </div>















			 <div className='cTabletLong2' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'800px',  maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={false} centerSlidePercentage={80}  > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1wl2} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2wl2}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3wl2}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6wl2}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4wl2}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5wl2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7wl2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8wl2}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9wl2}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
					TB-XL
				</div>
			 </div>







			


			 <div className='cWide2' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'420px',  maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'
paddingLeft='calc(6vw - 80px)'
paddingRight='calc(6vw - 80px)'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={33.5} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1u} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2u}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3u}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6u}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4u}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5u}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7u}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8u}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9u}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
				 4k-XW
				</div>
			 </div>







			 <div className='cWideLong2' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'450px',  maxHeight:'62vh' }}>
<Flex
direction='row'
justifyContent='center'
//paddingLeft='calc(4vw - 80px)'
//paddingRight='calc(4vw - 80px)'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={42} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1vl2} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2vl2}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3vl2}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6vl2}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4vl2}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5vl2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7vl2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8vl2}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9vl2}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
				 4k-W-XL
				</div>
			 </div>






			 <div className='cWide2Long' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'500px',  maxHeight:'62vh' }}>
<Flex
direction='row'
justifyContent='center'
paddingLeft='calc(6vw - 80px)'
paddingRight='calc(6vw - 80px)'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={33.5} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1ul} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2ul}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3ul}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6ul}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4ul}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5ul}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7ul}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8ul}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9ul}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
				 4k-XW-L 
				</div>
			 </div>






			 <div className='cWide2Long2' style={{flexDirection:'column',  position:'reletive' , justifyContent:'flex-end',  backgroundColor:'',   overflow: 'hidden' , zIndex:'6', minHeight:'500px',  maxHeight:'62vh'  }}>
<Flex
direction='row'
justifyContent='center'
paddingLeft='calc(6vw - 80px)'
paddingRight='calc(6vw - 80px)'


>

   <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} interval={3500} dynamicHeight={false} centerMode={true} centerSlidePercentage={33.5} > 
  				<Link to="/game/palworld">
				<div className='zoom'  >
				 
					 <img src={img1ul2} alt="" />
				
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						<div className=''>
						PALWOLRD
						</div>
					</p>
				</div>
				</Link>
				<Link to="/game/enshrouded">
				 <div  className='zoom'>
					 <img  src={img2ul2}  alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						ENSHROUDED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/vrising">
				 <div  className='zoom'>
					 <img   src={img3ul2}  alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						V RISING
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/sonsoftheforest">
				 <div  className='zoom'>
					 <img   src={img6ul2}     alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 SONS OF THE FOREST
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/valheim">
				 <div  className='zoom'>
					 <img   src={img4ul2}   alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
						VALHEIM
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/7daystodie">
				 <div  className='zoom'>
					 <img   src={img5ul2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 7-DAYS TO DIE
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/noonesurvived">
				 <div  className='zoom'>
					 <img   src={img7ul2}    alt=""  />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 NO ONE SURVIVED
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/corekeeper">
				 <div  className='zoom'>
					 <img    src={img8ul2}    alt="" />
					 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 CORE KEEPER
						</div>
						</p>
				 </div>
				 </Link>
				 <Link to="/game/terratechworlds">
				 <div  className='zoom'>
					 <img    src={img9ul2}   alt="" />
			 <p className="legend" style={{ fontSize:'30px', opacity:'.8' }} >
						
					 <div className=''>
					 TERRA TECH WORLDS
						</div>
						</p>
				 </div>
				 </Link>
			 </Carousel> 
			 </Flex>  
			 <div className="cType">
				 4k-XW-XL
				</div>
			 </div>








			 </div>





























 </>
		
	
	)
}

export default Home;
