import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
 Flex,
//  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Routes, Route} from 'react-router-dom';
import Home from './component/home';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.


//import About from './component/about';
import Notfound from './component/notfound';
import Tos from './component/tos';
import Help from './component/help';
import Faq from './component/faq';
import Games from './component/games';
import Palworld from './component/palworld';
import Enshrouded from './component/enshrouded';
import VRising from './component/v-rising';
import SevenDaysToDie from './component/7daystodie';
import Valheim from './component/valheim';
import Sonsoftheforest from './component/sonsoftheforest';
import Noonesurvived from './component/noonesurvived';
import Corekeeper from './component/corekeeper';
import Terratechworlds from './component/terratechworlds';

import MobileMenu from './component/mobile_menu';

import PrivPol from './component/privpol';
//import Ssllogo from "./th.jpg";
//import Smblogo from "./Badge_3.png";
//import bg from "./bg1a.jpg";
//import logo from './logo.svg';
//import background from "./ncs5a.jpg";
//import img1 from "./ncs10-1a.png";
//import img2 from "./ncs10-2a.png";
//import img3 from "./ncs10-3.jpg";

function App() {
	const [init, setInit] = useState(false);

	useEffect(() => {
		document.title = 'GServer.Cloud';


		initParticlesEngine(async (engine) => {
			// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
			// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
			// starting from v2 you can add only the features you need reducing the bundle size
			//await loadAll(engine);
			//await loadFull(engine);
			await loadSlim(engine);
			//await loadBasic(engine);
		  }).then(() => {
			setInit(true);
		  });



		}, []);




		const particlesLoaded = (container) => {
			console.log(container);
		  };
		
	


		  const options = useMemo(
			() => ({
			  background: {
				color: {
				  value: "#000000",
				},
			  },
			  fpsLimit: 120,
			  interactivity: {
				events: {
				  onClick: {
					enable: false,
					mode: "push",
				  },
				  onHover: {
					enable: false,
					mode: "repulse",
				  },
				},
				modes: {
				  push: {
					enable: false,
					quantity: 4,
				  },
				  repulse: {
					enable: false,
					distance: 200,
					duration: 0.4,
				  },
				},
			  },
			  particles: {
				color: {
				  value: '#FB8B24',
				},
				links: {
				  color: "#ffffff",
				  distance: 150,
				  enable: false,
				  opacity: 0.7,
				  width: 1,
				},
				move: {
				  direction: "none",
				  enable: true,
				  outModes: {
					default: "out",
				  },
				  random: true,
				  speed: 3,
				  straight: false,
				},
				number: {
				  density: {
					enable: true,
				  },
				  value: 120,
				},
				opacity: {
				  value: 0.08,
				},
				shape: {
				  type: "square",
				},
				size: {
				  value: { min: 10, max: 150 },
				},
				
			  },
			  detectRetina: true,
			}),
			[],
		  );

		  




  if ( window.location.pathname === '/help/'  ) {
    var tmp=true
  } else if ( window.location.pathname === '/tos/'  ) {
    tmp=true
  } else if ( window.location.pathname === '/privpol/'  ) {
    tmp=true
  } else {
    tmp= false 
  }







  
if (init) {
  

  return (


  

<div className= "App"  >

 


<div className= "Wrapper"  >



		  <div className= "Main" style ={{backgroundImage: ``, backgroundAttachment:'fixed', backgroundSize: "contain", backgroundRepeat: "no-repeat",  backgroundPosition: "bottom center" }}  >
		  
		  <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />




		
	<Routes>
				<Route exact path='/' element={< Home />}></Route>

				<Route path='/tos' element={< Tos />}></Route>
				<Route path='/help' element={< Help />}></Route>
				<Route path='/faq' element={< Faq />}></Route>
				<Route path='/games' element={< Games />}></Route>
				<Route path='/game/palworld' element={< Palworld />}></Route>
				<Route path='/game/enshrouded' element={< Enshrouded />}></Route>
				<Route path='/game/vrising' element={< VRising />}></Route>
				<Route path='/game/7daystodie' element={< SevenDaysToDie />}></Route>
				<Route path='/game/valheim' element={< Valheim />}></Route>
				<Route path='/game/sonsoftheforest' element={< Sonsoftheforest />}></Route>
				<Route path='/game/noonesurvived' element={< Noonesurvived />}></Route>
				<Route path='/game/corekeeper' element={< Corekeeper />}></Route>
				<Route path='/game/terratechworlds' element={< Terratechworlds />}></Route>

				<Route path='/menu' element={< MobileMenu />}></Route>
				<Route path='/privpol' element={< PrivPol />}></Route>
				<Route path="*" element={< Notfound />}></Route>
		</Routes>
		
	
		

	






</div>





<Flex
	direction='row'
	position='absolute'
	bottom='0'
	textAlign="center"
	width="96vw"
    gap='0'
	//backgroundColor="yellow"
	
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	minWidth="4vw"	
	  
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent='flex-end'
	width="94vw"
	//backgroundColor='white'
	//minHeight='2vw'
	height='auto'
	color='white'
	opacity='.5'
	//fontSize='2vw'
	>




		<span className="footText">
		 support@gserver.cloud | roark@comburent.io | comburent.io LLC &#169;2024
		 </span>



		 </Flex>





<Flex 
direction='column'
justifyContent="center"
alignItems="center"
textAlign="center"
grow='1'
>
</Flex>
</Flex>




</div>



</div>








  
  ); };








};


//export default withAuthenticator(App);
export default App;