import React from 'react';

import {
 Flex,
} from "@aws-amplify/ui-react";

import { Link } from 'react-router-dom';
import Navbar from './navbar_back';


function Mobile_menu (){

	const TEXTS1 = ['','MENU'];


	return (<>
	
	<div className = "Body" >

	
<Navbar></Navbar>







<div className='pageContentP'  >



<Flex
			direction="column"
		justifyContent='flex-start'
			alignItems="center"
			textAlign="center"
			gap='0'
			//backgroundColor='purple'
	
		>



<Flex		
			direction="column"
			alignItems="center"
			gap="0px"
			fontSize='calc(10px + 5vw)'
			opacity='.85'
		//	backgroundColor='blue'
	
			minWidth='100vw'
			>
			
			
    
	
			<span className = "pageContentHedderP">{TEXTS1}</span>

			

						</Flex>


	  










						<Flex
					direction='column'
					gap='30px'
				//	paddingTop="2.5vw"
				>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/faq">FAQ</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/games">ALL GAMES</Link>

					
<a className="gameLink1"
          href="https://app.gserver.cloud/signup"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >SIGN UP</a>
		<a className="gameLink1"
          href="https://app.gserver.cloud"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >LOG IN</a>

				
					
				</Flex>

	

	


		</Flex>













	</div>





























			 </div>
















 </>
		
	
	)
}

export default Mobile_menu;
