import React from 'react';

import {
 Flex,
} from "@aws-amplify/ui-react";

import { Link } from 'react-router-dom';
import Navbar from './navbar_other';


function Games (){

	const TEXTS1 = ['','ALL GAMES'];


	return (<>
	
	<div className = "Body" >

	
<Navbar></Navbar>




<Flex
	direction='row'
	
	bottom='0'
	textAlign="center"
	width="96vw"
    gap='0'
	//backgroundColor="yellow"
		paddingBottom='35px'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	minWidth="4vw"	
	  
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent='flex-end'
	width="94vw"
	height='auto'	
	>




<div className='pageContentP'  >


<Flex
			direction="row"
justifyContent='center'
			
			gap='0'
		//	backgroundColor='blue'
		    
		//	minWidth='96vw'
		grow="1"
		>


<Flex
			direction="column"

			alignItems="center"
			textAlign="center"
			gap='0'
		//	backgroundColor='purple'
		    minHeight='80vh'
		//	minWidth='96vw'
		>



<Flex
			direction="column"
			alignItems="center"
			gap="0"
			fontSize='calc(10px + 5vw)'
			opacity='.85'
	//		backgroundColor='blue'
			
		//	minWidth='96vw'
			>
			
			
    
	
			<span className = "pageContentHedderP">{TEXTS1}</span>

			

						</Flex>


	  










				<Flex
					direction='row'
					columnGap='100px'
					rowGap='20px'
					//paddingBottom='40px'
					//backgroundColor='yellow'
					maxWidth='60vw'
					wrap='wrap'
					justifyContent='center'
					//	paddingTop="2.5vw"
					
				>
					
					<Flex
						direction='column'>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/7daystodie">7-DAYS TO DIE</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/corekeeper">CORE KEEPER</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/enshrouded">ENSHROUDED</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/noonesurvived">NO ONE SURVIVED</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/palworld">PALWORLD</Link>
					</Flex>

					<Flex
						direction='column'>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/sonsoftheforest">SONS OF THE FOREST</Link>		
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/terratechworlds">TERRA TECH WORLDS</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/valheim">VALHEIM</Link>
					<Link className='gameLink1' style={{ textDecoration: 'none' }} to="/game/vrising">V RISING</Link>
					</Flex>
				
					
				</Flex>

	




	


		</Flex>

</Flex>


	</div>













	</Flex>





<Flex 
direction='column'
justifyContent="center"
alignItems="center"
textAlign="center"
grow='1'
>
</Flex>
</Flex>
















			 </div>
















 </>
		
	
	)
}

export default Games;
