import React from 'react';

import {
 Flex,
 // Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";

import { Link } from 'react-router-dom';
import login from '../login-logo1.png';
import menu from '../menu-logo2.png';

function Navbar_other (){


	return (<>
	
	
	
	

	<div className='navBarBox' >   
	

	
	






	
	<Flex
	direction='row'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	position='sticky'
	width="97vw"
	//	backgroundColor='yellow'
	gap='0'
	  overflow="hidden"
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	width="3vw"	
//	backgroundColor='green'
	  overflow="hidden"
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	width="96vw"
//backgroundColor='green'
  overflow="hidden"
	>





















	
	<Flex
	direction="row"
	justifyContent='space-between'
	//justifyContent='center'
	alignItems = "center"
	textAlign="center"
	alignContent='center'
	gap="0"
//	paddingTop='.5vw'
//	paddingBottom='.5vw'
//grow='1'
minWidth='98vw'
//	backgroundColor='yellow'
//	 overflow="hidden"
>


<div className='navbarPC' >

<Flex
 justifyContent='flex-start'
direction="row"
minWidth='23vw'
maxWidth='23w'
//boxShadow= "inset 0 0 0 1px grey"
paddingLeft='2vw'
gap="1.5vw"
//wrap="wrap"
 //overflow="hidden"
 
>




</Flex>

</div>


<div className='navbarMobile'>

	 <Flex
	  justifyContent='flex-start'
	 direction="row"
 //overflow="hidden"
	// boxShadow= "inset 0 0 0 1px grey"
	   >

<Link style={{ textDecoration: 'none',  paddingTop:'1vw', paddingLeft:'1vw'  }} to="/">
	
<img src={menu} alt="GSERVER.CLOUD MENU" className= "menuIconLeft" />

</Link>
		
</Flex>
		
</div>




<div>
<Flex
	direction="column"
	textAlign="center"
	//boxShadow= "inset 0 0 0 2px red"
	gap="0px"
	//maxWidth='40vw'
	//minWidth='35vw'	
	 overflow="hidden"
	>
	 <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">G<span  style={{ color: '#2568FB' }}  >SERVER</span>.CLOUD</Link>





	 </Flex>
	 </div>

<div className='navbarPC'>

	 <Flex
	 justifyContent='flex-end'
	 direction="row"
	 minWidth='23vw'
	 maxWidth='23vw'
	//boxShadow= "inset 0 0 0 1px grey"
	 paddingRight='2vw'
	// /  wrap="wrap"
gap="1.5vw"
 //overflow="hidden"
	   >


<a className="navBarLink3blue"
          href="https://app.gserver.cloud/signup"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >SIGN UP</a>
		<a className="navBarLink3"
          href="https://app.gserver.cloud"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >LOG IN</a>
		
</Flex>
		
</div>

<div className='navbarMobile'>

	 <Flex
	 justifyContent='flex-end'
	 direction="row"
 //overflow="hidden"
	//  boxShadow= "inset 0 0 0 1px grey"
	 
	  
	  
	   >



		<a className="navBarLink3Mobile"
          href="https://app.gserver.cloud"
          target="_self"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' , paddingTop:'1vw', paddingRight:'1vw'}}
        >

<img src={login} alt="GSERVER.CLOUD LOGIN" className= "menuIconRight"  />

		</a>
		
</Flex>
		
</div>





</Flex>









</Flex>





<Flex 
direction='column'
justifyContent="center"
alignItems="center"
textAlign="center"
grow='1'
  overflow="hidden"
>
</Flex>
</Flex>












</div>










	
	















 </>
		
	
	)
}

export default Navbar_other;