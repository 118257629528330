import React from 'react';

import {
 Flex,
} from "@aws-amplify/ui-react";

import { Link } from 'react-router-dom';
import Navbar from './navbar_other';


function Faq (){

	const TEXTS1 = ['','FAQ'];

	const TEXTS1a = ['',"What is the GSC advantage?"];
	const TEXTS1b = ['',"We provide performance focus game servers, using real world data collected from our systems to maintain high server output!"];
   
	const TEXTS2a = ['',"Where are your datacenters located?"];
	const TEXTS2b = ['',"We offer low-latency service in the US West/Central and US East Regions.  More coming soon!"];
   
	const TEXTS3a = ['',"How soon will my server be up and running?"];
	const TEXTS3b = ['',"Typically your server will be up and running within 15min of completing payment with PayPal!"];
	
	const TEXTS4a = ['',"How do I maintain my Game Server?"];
	const TEXTS4b = ['',"We provide a simplified control panel for viewing server status, managing backups, changing settings, and restarting/updating!"];
   
	const TEXTS5a = ['',"How do I connect to my Game Server?"];
	const TEXTS5b = ['',"A unique IP address and port number will be assigned to your server.  This information can be directly entered into the game to directly connect!"];
   
	const TEXTS6a = ['',"What if I run into problems?"];
	const TEXTS6b = ['',"Restarting a server usually fixes most issues. If not, you can reset your server to its initial working state or restore it from a previous working backup.  Additionally, the GSC Support Team is here to assist at support@gservers.cloud! "];
   

	
	return (<>
	
	<div className = "Body" >

	
<Navbar></Navbar>





<Flex
	direction='row'
	
	bottom='0'
	textAlign="center"
	width="96vw"
    gap='0'
//	backgroundColor="yellow"
	paddingBottom='35px'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	minWidth="4vw"	
	  
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent='flex-end'
	width="94vw"
	height='auto'
	>




<div className='pageContentP'  >



<Flex
			direction="column"
	//gap='calc(3vw + 10px)'
	gap='0'
			alignItems="center"
			textAlign="center"
		paddingRight='7vw'
		paddingLeft='7vw'
		//	backgroundColor='purple'
		    minHeight='80vh'
	//		minWidth='100vw'
	//	paddingTop='calc(20px - 1vw)'
	//paddingBottom='40px'
		>



<Flex
			direction="column"
			alignItems="center"
			
			fontSize='calc(10px + 5vw)'
			opacity='.85'
		//	backgroundColor='blue'
	
		//	minWidth='100vw'
gap='0'
	
		
			>
			
			
    
	
			<span className = "pageContentHedderP">{TEXTS1}</span>

			

						</Flex>


	  








						<Flex
			direction="column"
			alignItems="center"
			gap='calc(12px + 1.1vw)'
		//	backgroundColor='green'
			
			>
			

						<span style={{	marginTop:'calc(-.3vw - 4px)'}}>
		<span className = "pageContent2"  >{TEXTS1a}</span><br></br>
		<span className = "pageContentFaq"  >{TEXTS1b}</span>
		</span>

		<span>
		<span className = "pageContent2"  >{TEXTS2a}</span><br></br>
		<span className = "pageContentFaq"  >{TEXTS2b}</span>
		</span>

		<span>
		<span   className = "pageContent2"  >{TEXTS3a}</span><br></br>
		<span className = "pageContentFaq"   >{TEXTS3b}</span>
		</span>

		<span>
		<span  className = "pageContent2" >{TEXTS4a}</span><br></br>
		<span className = "pageContentFaq" >{TEXTS4b}</span>		
		</span>

		<span>
		<span  className = "pageContent2" >{TEXTS5a}</span><br></br>
		<span className = "pageContentFaq"  >{TEXTS5b}</span>		
		</span>

		<span>
		<span  className = "pageContent2" >{TEXTS6a}</span><br></br>
		<span className = "pageContentFaq" >{TEXTS6b}</span>
		</span>

	
			
</Flex>




	


		</Flex>




	</div>









	</Flex>





<Flex 
direction='column'
justifyContent="center"
alignItems="center"
textAlign="center"
grow='1'
>
</Flex>
</Flex>























			 </div>
















 </>
		
	
	)
}

export default Faq;
