import React from 'react';
import {
 Flex
} from "@aws-amplify/ui-react";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


function Notfound (props){


	return (<>
	
	

	






	
<Flex
	direction='row'
	justifyContent="center"
	alignItems='flex-start'
	textAlign="center"
	position='sticky'
	width="96vw"
	
	//backgroundColor='red'
	gap='0'
	//height='92vh'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	width="4vw"
	gap='0'
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent="flex-start"
	alignItems="center"
	textAlign="center"
	width="94vw"
	gap='0'
	//minHeight='80vh'
	//backgroundColor='cyan'
	//marginTop='10px'
	>




<div className='pageContentH'>




<div >
			<Flex
			color="white"
			paddingTop="10vh"
			
			marginLeft="15vw"
			marginRight="15vw"
			fontSize="10vh"
			>
				
			404 - The content you are looking is not available			
			</Flex>
			</div>




	</div>
	




	</Flex>





	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	grow='1'
	>
	</Flex>
</Flex>






	






 </>
		
	
	)
}

export default Notfound;
