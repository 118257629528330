import React from 'react';

import {
 Flex,
} from "@aws-amplify/ui-react";

import { Link } from 'react-router-dom';
import Navbar from './navbar_other';


function Tos (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 //var tmpgap = '0rem';

 const TEXTS1 = ['','HELP'];


	return (<>
	


	<div className = "Body" >

	
<Navbar></Navbar>





<Flex
	direction='row'
	
	bottom='0'
	textAlign="center"
	width="96vw"
    gap='0'
	//backgroundColor="yellow"
	paddingBottom='35px'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	minWidth="4vw"	
	  
	>
	</Flex>
	
	
<Flex
	direction='column'
	justifyContent='flex-end'
	width="94vw"
	height='auto'
	>




<div className='pageContentP'  >



<Flex
			direction="column"
	//gap='calc(3vw + 10px)'
	gap='0'
			alignItems="center"
			textAlign="center"
		paddingRight='7vw'
		paddingLeft='7vw'
			//backgroundColor='purple'
		    minHeight='80vh'
	//		minWidth='100vw'
	//	paddingTop='calc(20px - 1vw)'
	//paddingBottom='40px'
	grow='1'
		>



<Flex
			direction="column"
			alignItems="center"
			
			fontSize='calc(10px + 5vw)'
			opacity='.85'
			//backgroundColor='blue'
	
		//	minWidth='100vw'
gap='0'
	
		
			>
			
			
    
	
			<span className = "pageContentHedderP">{TEXTS1}</span>

			

						</Flex>


	  








						<Flex
			direction="column"
			justifyContent='center'
			alignItems="center"
			//gap='calc(12px + 1.1vw)'
			//backgroundColor='green'
			minWidth='70vw'
			//grow='1'
			>
			

						





















<div className='helpContentSmall2' >
<br></br>
	






	<Flex 
textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>GENERAL USE</span></Flex>
<br></br>
-The status of the game server will be shown as RUNNING, RESTARTING or UPDATING<br></br>
-To restart and update the game server, use the RESTART button<br></br>
-To change the game server's settings, use the OPTIONS button<br></br>
-The game server's internet address is shown below the status, in the format '1.1.1.1:1234' <br></br>
<br></br> 

<Flex 





textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>ADDITIONAL FEATURES</span></Flex>
<br></br>
-<br></br>
-<br></br>
-<br></br>
<br></br>










	</div>
	










	
			
</Flex>




	


		</Flex>




	</div>









	</Flex>





<Flex 
direction='column'
justifyContent="center"
alignItems="center"
textAlign="center"
grow='1'
>
</Flex>
</Flex>























			 </div>






























 </>
		
	
	)
}

export default Tos;
